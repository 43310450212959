<template>
  <Feedback :title="title" :content="message" @close="$emit('close')">
    <template slot="icon">
      <div class="flex flex-col items-center gap-1 mb-4">
        <Medal :name="label" :picture="picture" />
      </div>
    </template>
    <template slot="actions">
      <div class="d-flex flex-column align-items-center w-100 mt-2">
        <ButtonPlay :text="$t('gamification.see_my_medals')" class="w-100 mb-2" @click="action" icon="buy-crypto"
          iconType="bold" type="normal" />
      </div>
    </template>
  </Feedback>
</template>
<script>

export default {
  components: {
    Medal: () => import("@/components/Medal.vue")
  },
  props: {
    title: String,
    message: String,
    picture: String,
    label: String,
  },
  methods: {
    action() {
      this.$emit("close");
      this.$router.push('/gamification?tab=medals');
    }
  }
}
</script>
