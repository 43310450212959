<template>
  <b-modal :visible="true" modal-class="feedback-modal-play" body-class="p-0" content-class="p-0" header-class="p-0"
    footer-class="p-0 border-0 mt-3" hide-header hide-footer :no-close-on-esc="noCloseOnEsc"
    :no-close-on-backdrop="noCloseOnBackdrop" @close="close" @hidden="close">
    <b-overlay :id="'overlay-background-' + id" :show="isLoading" bg-color="var(--backgroundcolor)" opacity="1"
      rounded="sm">
      <div class="feedback-modal-play__header d-flex justify-content-end w-100">
        <IconSax name="close-circle" color="var(--fontcolor)" @click="close" />
      </div>

      <div class="feedback-modal-play__body">
        <slot>
          <div class="feedback-modal-play__body__container py-3 d-flex flex-column">
            <slot name="icon">
              <IconSax v-if="status" class="mb-3" size="3.75rem"
                :name="status == 'error' ? 'close-circle' : 'tick-circle'"
                :color="status == 'error' ? 'var(--feedback-error-500)' : 'var(--feedback-success-500)'" />
            </slot>

            <slot name="title">
              <TextPlay class="feedback-modal-play__body__title mb-3" v-if="title" weight="semibold"
                color="var(--fontcolor)" size="xl" :text="title" />
            </slot>

            <slot name="content">
              <TextPlay class="feedback-modal-play__body__content" color="var(--text-color)" v-html="content" />
            </slot>
          </div>
        </slot>
      </div>

      <div class="feedback-modal-play__actions w-100">
        <slot name="actions">
          <div v-if="confirmBtnText || denyBtnText" class="d-flex flex-column align-items-center w-100 mt-2">
            <ButtonPlay v-if="confirmBtnText" class="w-100 mb-2" @click="$emit('confirm')" :text="confirmBtnText"
              :icon="confirmBtnIcon" :iconType="confirmBtnIconType" type="normal" />

            <ButtonPlay v-if="denyBtnText" class="w-100" @click="$emit('deny')" :text="denyBtnText"
              type="feedback-error" :icon="denyBtnIcon" :iconType="denyBtnIconType" />
          </div>
        </slot>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    status: {
      type: String,
    },
    confirmBtnText: {
      type: String,
      default: "",
    },
    confirmBtnIcon: {
      type: String,
      default: "",
    },
    confirmBtnIconType: {
      type: String,
      default: "regular",
    },
    denyBtnText: {
      type: String,
      default: "",
    },
    denyBtnIcon: {
      type: String,
      default: "close-circle",
    },
    denyBtnIconType: {
      type: String,
      default: "regular",
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    show({
      title,
      content,
      status,
      confirmBtnText,
      confirmBtnIcon,
      denyBtnText,
      denyBtnIcon,
      denyBtnIconType,
      confirmBtnIconType,
    }) {
      this.title = title;
      this.content = content;
      this.status = status;
      this.confirmBtnText = confirmBtnText;
      this.denyBtnText = denyBtnText;
      this.confirmBtnIcon = confirmBtnIcon;
      this.denyBtnIcon = denyBtnIcon;
      this.denyBtnIconType = denyBtnIconType;
      this.confirmBtnIconType = confirmBtnIconType;

      this.$bvModal.show(this.id);
    },
    hide() {
      this.$bvModal.hide(this.id);
    },
    reset() {
      this.title = "";
      this.content = "";
      this.status = "";
      this.confirmBtnText = "";
      this.denyBtnText = "";
      this.confirmBtnIcon = "";
      this.denyBtnIcon = "";
      this.denyBtnIconType = "regular";
      this.confirmBtnIconType = "regular";
    },
  },
  mounted() {
    this.show()
  }
};
</script>

<style lang="scss">
.feedback-modal-play {
  .modal-dialog {
    width: 485px;
  }

  .modal-content {
    padding: 40px 48px !important;
  }

  .spinner-border {
    color: var(--maincolor);
  }
}

@media (max-width: 768px) {
  .feedback-modal-play {
    .modal-dialog {
      margin: auto;
      width: 85%;
    }
  }
}
</style>

<style lang="scss" scoped>
.feedback-modal-play {
  &__header {
    .isax {
      cursor: pointer;
    }
  }
}
</style>
