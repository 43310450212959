<template>
  <Modal :visible="true" id="modal-member-create" size="lg" :title="$t('members.modal_create.title')"
    @close="$emit('close')">
    <b-form @submit.stop.prevent>
      <fieldset>
        <b-form-group :label="$t('members.modal_create.labels.name')" label-for="name" id="step4">
          <b-form-input name="name" id="name" v-model="name" v-validate="{ required: true }"
            :state="!errors.has('name')" :placeholder="$t('members.modal_create.placeholder.name')"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('members.modal_create.labels.email')" label-for="name" id="step4">
          <b-form-input name="email" id="email" type="email" v-model="email" v-validate="{ required: true }"
            :state="!errors.has('email')" :placeholder="$t('members.modal_create.placeholder.name')" />
        </b-form-group>
        <b-form-group :label="$t('members.modal_create.labels.cellphone')" label-for="name" id="step4">
          <b-form-input name="cellphone" id="cellphone" v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="cellphone" v-validate="{ required: true }"
            :placeholder="$t('members.modal_create.placeholder.cellphone')" :state="!errors.has('cellphone')" />
        </b-form-group>
        <b-form-group :label="$t('members.modal_create.labels.cpf_cnpj')" label-for="name" id="step4">
          <b-form-input name="cpf_cnpj" id="cpf_cnpj" v-mask="['###.###.###-##', '##.###.###/####-##']"
            v-model="cpf_cnpj" v-validate="{ required: true }"
            :placeholder="$t('members.modal_create.placeholder.cpf_cnpj')" :state="!errors.has('cpf_cnpj')" />
        </b-form-group>
        <b-form-group :label="$t('members.modal_create.labels.type')" label-for="course_class_ids">
          <SelectPlay name="type" v-model="type" :placeholder="$t('members.modal_create.placeholder.type')"
            :options="options" v-validate="{ required: true }" :state="!errors.has('type')" open-direction="top" />
        </b-form-group>
        <b-form-group key="course_class_ids" v-if="type === 'course_class'" :label="$t('members.modal_create.labels.class')"
          label-for="course_class_ids">
          <MultiselectPlay id="course_class_ids" name="course_class_ids" v-model="course_class_ids" label="label" track-by="id"
            :placeholder="$t('members.modal_create.placeholder.class')" :options="class_options" :multiple="true" open-direction="top" />
        </b-form-group>
        <b-form-group key="signature_ids" v-if="type === 'signature'" :label="$t('members.modal_create.labels.signature')"
          label-for="signature_ids">
          <MultiselectPlay id="signature_ids" name="signature_ids" v-model="signature_ids" label="label" track-by="id"
            :placeholder="$t('members.modal_create.placeholder.signature')" :options="signature_options"
            :multiple="true" open-direction="top" />
        </b-form-group>
      </fieldset>
    </b-form>
    <template slot="footer">
      <Divider />
      <div class="flex-row justify-between items-center">
        <TextPlay color="var(--legend-color)" :text="$t('members.modal_create.legend')" size="sm" />
        <ButtonPlay type="normal" @click="submit" :text="$t('members.modal_create.submit')" />
      </div>
    </template>
  </Modal>
</template>
<script>
import fp from "lodash/fp"
import { mapActions } from "vuex";

export default {
  data() {
    return {
      name: "",
      email: "",
      cellphone: "",
      cpf_cnpj: "",
      type: undefined,
      signature_ids: [],
      course_class_ids: [],
      loading: false,
      class_options: [],
      signature_options: [],
      options: [
        { id: "course_class", label: this.$t('members.modal_create.labels.class') },
        { id: "signature", label: this.$t('members.modal_create.labels.signature') },
      ],
    };
  },
  methods: {
    ...mapActions({
      'actionClassLoadSelect': 'courseClass/actionLoadSelect',
      'actionSignatureLoadSelect': 'signatures/actionLoadSelect',
      'actionManualSave': 'user/actionManualSave',
    }),
    async loadClasses() {
      this.$root.$emit("loadOn");
      try {
        this.class_options = await serviceClass.read("/select")
      } finally {
        this.$root.$emit("loadOff");
      }
    },
    async loadSignatures() {
      this.$root.$emit("loadOn");
      try {
        this.signature_options = await serviceSignature.read("/select")
      } finally {
        this.$root.$emit("loadOff");
      }
    },
    customLabel({ title }) {
      return `${title}`;
    },
    async submit() {
      this.loading = true;
      const data = {
        id: "manual-registration",
        name: this.name,
        email: this.email,
        cellphone: this.cellphone,
        cpf_cnpj: this.cpf_cnpj,
        type: this.type,
      };

      await this.$validator.validateAll(Object.assign(data));

      if (this.type === null) {
        this.$bvToast.toast(this.$t("members.modal_create.type_necessary"), {
          title: this.$t("members.modal_create.title"),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      if (this.type === "course_class") {
        data.course_class_ids = fp.map(fp.get('id'), this.course_class_ids);
      } else {
        data.signature_ids = fp.map(fp.get('id'), this.signature_ids);
      }

      try {
        const response = await this.actionManualSave(data);
        if (response.status === "updated") {
          this.$root.$bvToast.toast(this.$t("members.modal_create.toasts.updated"), {
            title: this.$t("members.modal_create.toasts.title"),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        } else {
          this.$root.$bvToast.toast(this.$t("members.modal_create.toasts.inserted"), {
            title: this.$t("members.modal_create.toasts.title"),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
        this.$emit('close');
        this.$forceUpdate();
      } catch (err) {
        var error = JSON.parse(err.response.data);
        var msg = "";
        for (var indice in error) {
          msg += error[indice][0] + "\n";
        }
        if (msg !== "") {
          this.$bvToast.toast(msg, {
            title: this.$t("members.modal_create.toasts.title"),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
            bodyClass: "pre-line"
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    const [classList, signatureList] = await Promise.all([
      this.actionClassLoadSelect(),
      this.actionSignatureLoadSelect()
    ]);
    this.class_options = classList;
    this.signature_options = signatureList;
  },
};
</script>
<style lang="scss">
#modal-member-create {

  fieldset {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .form-control {
    border: 1px solid var(--input-border) !important;
    box-sizing: border-box !important;
    height: 48px !important;
    border-radius: 8px !important;
  }

  .form-control.is-invalid {
    border: 1px solid var(--dangercolor) !important;
  }

  .form-control:hover,
  .form-control:focus {
    border: 1px solid var(--maincolor) !important;
  }

  .form-control::placeholder {
    color: var(--input-placeholder) !important;
    font-weight: 400 !important;
    font-size: 14px;
  }

  .legend {
    color: var(--legend-color);
  }

}

.pre-line {
  white-space: pre-line;
}

</style>
